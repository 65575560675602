import { FormGroup } from '@angular/forms';
import moment from 'moment';
import Swal from "sweetalert2";
import { TokenStorageService } from "../services/token-storage.service";

const patterns = {
  URLRegex: /^(?:(?:https?:\/\/)|(?:www\.))([a-zA-Z0-9-]+\.)+\w{2,}(?:\/[^/\s]+)*\/?$/
}

const getFileName = (url: string): string => {
  const match = url.match(/\/([^\/?#]+)[^\/]*$/);
  return match ? match[1] : "";
}

// Create a file object with the image URL
const getImgFileFromFileURL = (imageURL: string, fileName: string): File | null => {
  if (imageURL && fileName) {
    return new File([imageURL], fileName, { type: "image/png" });
  } else {
    return null;
  }
};

function formatPhoneNumber(phoneNumber: any) {
  return phoneNumber?.replace(/\D/g, '');
}

function formatPhoneNumberPattern(number: any) {
  // Remove all non-digit characters from the input string
  const cleaned = number?.replace(/\D/g, '');

  // Apply the desired phone number format
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  // Return the original value if it doesn't match the expected format
  return number;
}

function getUserType(): {
  isSuperAdmin: boolean;
  isAdmin: boolean;
  isCoach: boolean;
} {
  let isSuperAdmin = false;
  let isAdmin = false;
  let isCoach = false;
  const tokenServie = new TokenStorageService();
  const currentUser = tokenServie.getUser();
  isAdmin = currentUser?.roles?.includes('admin');
  isSuperAdmin = currentUser?.roles?.includes('superAdmin');
  isCoach = currentUser?.roles?.includes('coach');
  return {
    isSuperAdmin,
    isAdmin,
    isCoach
  }
}

const showAlertMessage = (text: string, type: 'success' | 'error', onCloseCallback?: () => void, titleText?: string) => {
  const imageUrl = type === 'success' ? 'assets/images/success@2x.png' : 'assets/images/info.png';
  const textBelowIcon = type === 'success' ? 'Tap anywhere to continue.' : 'Tap anywhere to try again.';
  Swal.fire({
    title: '',
    html: `
      <div style="text-align: center; ">
        <p class="mt-5 text-lg !text-[#3B3B3B] dark:!text-[#92AFD3] font-normal"> ${text}</p>
        <div style="display: flex; justify-content: center; margin: 20px 0px 20px 0px"><img style="height: 74px; width: 74px;" src="${imageUrl}" alt="success"/></div>
        <div><p class="!text-[#3B3B3B] dark:!text-[#92AFD3] text-sm"> ${textBelowIcon} </p> </div>
      </div>
    `,
    icon: undefined,
    showConfirmButton: false,
    animation: false,
    buttonsStyling: false,
    showCloseButton: true,
    allowOutsideClick: true,
    customClass: {
      popup: 'custom-popup-class',
      container: 'custom-container-class'
    },
    didOpen: (popup) => {
      popup.addEventListener('click', () => {
        Swal.clickConfirm();
      });
    },
    didClose: onCloseCallback
  });
}

interface IROLES {
  isSKStaff: boolean;
  isSKSuperAdmin: boolean;
}
const checkRole = (): IROLES => {
  const tokenServie = new TokenStorageService();
  const user = tokenServie.getUser();
  let isSKStaff = false;
  let isSKSuperAdmin = false;
  if (user) {
    if (user?.roles?.includes('superAdmin')) {
      isSKSuperAdmin = true;
    }
    if (user?.roles?.includes('sketchplayStaff')) {
      isSKStaff = true;
    }
  }
  return {
    isSKStaff,
    isSKSuperAdmin
  }
}

function checkIfUserIsParent(userDetails: any): boolean {
  const hasParentRole = userDetails?.organizations?.some((org: any) => org.roles.some((role: any) => role.type === 'parent'));
  return hasParentRole;
}

function checkIfUserIsAthlete(userDetails: any): boolean {
  const hasAthleteRole = userDetails?.organizations?.some((org: any) => org.roles.some((role: any) => role.type === 'athlete'));
  return hasAthleteRole;
}

function checkIfUserIsAdmin(userDetails: any): boolean {
  const hasAthleteRole = userDetails?.organizations?.some((org: any) => org.roles.some((role: any) => role.type === 'admin'));
  return hasAthleteRole;
}

function checkIfUserIsAdminInAllOrg(userDetails: any): boolean {
  const hasAdminRole = userDetails?.roles?.includes('superAdmin');
  return hasAdminRole;
}

function rgbToHex(rgb: any) {
  // Extract RGB values using regular expressions
  const rgbValues = rgb?.match(/\d+/g);

  if (rgbValues.length === 3) {
    var [r, g, b] = rgbValues.map(Number);
  }
  // Ensure the values are within the valid range (0-255)
  r = Math.max(0, Math.min(255, r));
  g = Math.max(0, Math.min(255, g));
  b = Math.max(0, Math.min(255, b));

  // Convert each component to its hexadecimal representation
  const rHex = r.toString(16).padStart(2, '0');
  const gHex = g.toString(16).padStart(2, '0');
  const bHex = b.toString(16).padStart(2, '0');

  // Combine the hexadecimal values with the "#" prefix
  const hexColor = `#${rHex}${gHex}${bHex}`;

  return hexColor.toUpperCase(); // Convert to uppercase for consistency
}

function getChartColorsArray(colors: any) {
  const parsedColors = JSON.parse(colors);
  const mappedColors = parsedColors.map((value: any) => {
    const newValue = value?.replace(/\s/g, "");
    if (!newValue.includes("#")) {
      const element = document.querySelector(newValue);
      if (element) {
        const styles = window.getComputedStyle(element);
        const backgroundColor = styles.backgroundColor;
        return backgroundColor || newValue;
      } else {
        const divElement = document.createElement('div');
        divElement.className = newValue;
        document.body.appendChild(divElement);

        const styles = window.getComputedStyle(divElement);
        const backgroundColor = styles.backgroundColor.includes("#") ? styles.backgroundColor : rgbToHex(styles.backgroundColor);
        return backgroundColor || newValue;
      }
    } else {
      return newValue;
    }
  });
  return mappedColors;
}

function dobTimezoneFormat(date: string | Date, format?: string) {
  if (typeof date === 'string') {
    return moment.utc(date).format(format ? format : "YYYY-MM-DD");
  } else {
    return moment(date).format(format ? format : "YYYY-MM-DD");
  }
};

function parentChildOrganization(orgClients: any) {
  const result: any = [];
  orgClients.forEach((client: any) => {
    // Add the parent organization
    result.push({
      id: client.id,
      name: client.name,
      isSubOrganization: false,
      isParent: true,
      roles: client.roles
    });

    // Add the sub-organizations
    client.subOrganizations.forEach((subOrg: any) => {
      result.push({
        id: subOrg.id,
        name: subOrg.name, // Add indentation
        isSubOrganization: true,
        parentOrgId: client.id,
        isParent: false,
        roles: subOrg.roles
      });
    });
  });
  return result;
}

function setVideoThumbnailImage(url: string): string {
  if (url) {
    return url;
  } else {
    return 'assets/images/video-poster-img.jpg';
  }
}

function extractFileParts(filename: string): string {
  if (filename) {
    // Extract the first 5 characters
    const firstPart = filename.slice(0, 5);
    // Find the position of the last dot
    const dotIndex = filename.lastIndexOf('.');
    // Extract the last 5 characters before the dot
    const lastPart = filename.slice(dotIndex - 5, dotIndex);
    // Extract all characters after the dot
    const extension = filename.slice(dotIndex);
    // Combine the parts
    return `${firstPart}.....${lastPart}${extension}`;
  } else {
    return '';
  }
}

function charValidate(text: string, limit: number): string {
  if (text !== undefined && limit !== undefined) {
    if (text !== null) {
      if (text.length > limit) {
        return `${text.substring(0, limit - 3)}...`;
      } else {
        return `${text.substring(0, limit)}`;
      }
    } else {
      return "";
    }
  } else {
    return "";
  }
};

function focusFirstInvalidInput(formGroup: FormGroup, controlNames: string[]): void {
  for (const controlName of controlNames) {
    const control = formGroup.get(controlName);
    if (control && control.invalid) {
      setTimeout(() => {
        const element = document.getElementById(controlName);
        if (element) {
          element.focus();
        }
      });
      return;
    }
  }
}


export {
  charValidate, checkIfUserIsAdmin, checkIfUserIsAdminInAllOrg,
  checkIfUserIsAthlete,
  checkIfUserIsParent, checkRole, dobTimezoneFormat, extractFileParts, focusFirstInvalidInput, formatPhoneNumber, formatPhoneNumberPattern, getChartColorsArray, getFileName,
  getImgFileFromFileURL,
  getUserType, parentChildOrganization, patterns, setVideoThumbnailImage, showAlertMessage
};

