import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, from } from 'rxjs';
import { getFirebaseBackend } from '../../authUtils';
import { User } from '../../store/Authentication/auth.models';
import { TokenStorageService } from './token-storage.service';


@Injectable({ providedIn: 'root' })

export class AuthenticationService {

  user!: User;

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
  }

  /**
   * Returns the current user
   */
  public currentUser(): User {
    return getFirebaseBackend().getAuthenticatedUser();
  }


  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string) {
    return from(getFirebaseBackend().loginUser(email, password).then(user => {
      return user;
    })
    );
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  register(email: any, username: any, password: any) {
    return from(getFirebaseBackend().registerUser(email, username, password).then((response: any) => {
      const user = response;
      return user;
    }));
  }

  changePassword(payload: object): Observable<any> {
    let token = this.tokenStorageService.getToken();
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    let options = { headers: headers };
    return this.http.post(environment.API + 'admin-user/change-password', payload, options);
  }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(email: string) {
    return getFirebaseBackend().forgetPassword(email).then((response: any) => {
      const message = response.data;
      return message;
    });
  }

  /**
   * Logout the user
   */
  logout() {
    // logout the user
    getFirebaseBackend()?.logout();
  }
  forgotPassword(res: any): Observable<any> {
    return this.http.post(environment.API + 'admin-user/send-otp', res);
  }

  userMe(): Observable<any> {
    // let token = localStorage.getItem('token')
    // const headers = new HttpHeaders({
    //     'Authorization': `Bearer ${token}`
    // });
    // const options = { headers: headers };
    return this.http.get(environment.API + 'me');
  }
  enterOtp(res: any): Observable<any> {
    return this.http.post(environment.API + 'admin-user/forgot-password', res);
  }
  resendOtp(res: any): Observable<any> {
    return this.http.post(environment.API + 'admin-user/send-otp', res);
  }
}

