import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, catchError, throwError } from 'rxjs';
import { User } from '../../store/Authentication/auth.models';
import { showAlertMessage } from '../helpers/utils';
import { TokenStorageService } from './token-storage.service';

@Injectable({ providedIn: 'root' })
export class UserProfileService {

  constructor(
    public http: HttpClient,
    private tokenStorageService: TokenStorageService,
    private ngxSpinnerService: NgxSpinnerService,
  ) { }


  // private handleError = (error: any) => {
  //   if (error.error instanceof ErrorEvent) {
  //     Swal.fire({
  //       title: 'Error',
  //       text: error.error.message,
  //       icon: 'warning',
  //       iconColor: 'red',
  //       showConfirmButton: false,
  //       animation: false,
  //       buttonsStyling: false,
  //       showCloseButton: true
  //     });
  //   } else {
  //     Swal.fire({
  //       title: 'Error',
  //       text: error ?? 'Something bad happened; please try again later.',
  //       icon: 'warning',
  //       iconColor: 'red',
  //       showConfirmButton: false,
  //       animation: false,
  //       buttonsStyling: false,
  //       showCloseButton: true
  //     });
  //   }
  //   this.ngxSpinnerService.hide();
  //   return throwError(() => 'Something bad happened; please try again later.');
  // }
  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      showAlertMessage(error.error.message ?? "Something went wrong!", "error");
    } else {
      showAlertMessage(error ?? "Something went wrong!", "error");
    }
    return throwError(() => 'Something bad happened; please try again later.');
  }

  // constructor(private http: HttpClient) { }
  /***
   * Get All User
   */
  getAll() {
    return this.http.get<User[]>(`api/users`);
  }

  /***
   * Facked User Register
   */
  register(user: User) {
    return this.http.post(`/users/register`, user);
  }

  getAllRoles(): Observable<any> {
    const token = this.tokenStorageService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get<any>(`${environment.API}all-roles`, options).pipe(catchError(this.handleError))
  }

  getMemberRoles(): Observable<any> {
    const token = this.tokenStorageService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get<any>(`${environment.API}admin-user/roles`, options).pipe(catchError(this.handleError))
  }

  addVideo(payload: any, videoID: any): Observable<any> {
    const token = this.tokenStorageService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    if (videoID !== 'add') {
      return this.http.put<any>(`${environment.API}help-video`, payload, options).pipe(catchError(this.handleError))
    }
    return this.http.post<any>(`${environment.API}help-video`, payload, options).pipe(catchError(this.handleError))
  }

  addAdmin(payload: any, memberID: any): Observable<any> {
    const token = this.tokenStorageService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    if (memberID !== "addStaff" && memberID !== "addAdmin") {
      return this.http.patch<any>(`${environment.API}admin-user/${memberID}`, payload, options).pipe(catchError(this.handleError))
    }
    return this.http.post<any>(`${environment.API}admin-user`, payload, options).pipe(catchError(this.handleError))
  }

  getMemberByID(payload: { id: number | string }): Observable<any> {
    const token = this.tokenStorageService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get<any>(`${environment.API}admin-user/${payload?.id}`, options).pipe(catchError(this.handleError))
  }

  deleteMemberByID(payload: { id: number | string, isAdmin: boolean | string }): Observable<any> {
    const token = this.tokenStorageService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.delete<any>(`${environment.API}admin-user/${payload?.id}/${payload?.isAdmin}`, options).pipe(catchError(this.handleError))
  }


  getVideoDetailsByID(payload: { id: number | string }): Observable<any> {
    const token = this.tokenStorageService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get<any>(`${environment.API}help-video/${payload?.id}`, options).pipe(catchError(this.handleError))
  }

  deleteVideoDetailsByID(payload: { id: number | string }): Observable<any> {
    const token = this.tokenStorageService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.delete<any>(`${environment.API}help-video/${payload?.id}`, options).pipe(catchError(this.handleError))
  }

  getUsers(): Observable<any> {
    const token = this.tokenStorageService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const options = { headers: headers };
    return this.http.get<any>(`${environment.API}user/all?skip=0&limit=1000`, options).pipe(catchError(this.handleError))
  }
}
